import { useAuth, withAuthenticationRequired } from 'react-oidc-context';
import { Loader } from '@intellecteu/common-ui';
import { useNotifications } from './hooks';

const PrivateRoute = ({ children }: any) => {
  const auth = useAuth();
  const { showErrorMessage } = useNotifications();

  if (auth.error) {
    return (
      <>
        <h1>Error</h1>
        {showErrorMessage(auth.error?.message)}
      </>
    );
  }

  if (auth.isLoading) {
    return <Loader.FullPage />;
  }

  if (auth.isAuthenticated) {
    return children;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return <div>Redirecting...</div>;
  }

  return showErrorMessage('Unable to sign in');
};

export default withAuthenticationRequired(PrivateRoute, {
  OnRedirecting: () => {
    return <div>Redirecting...</div>;
  },
});
