import React, { useRef, useEffect, useState, HTMLProps, RefObject } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import cx from 'classnames';
import { Icon, IconButton, Text, type ButtonProps } from '@intellecteu/common-ui';
import { useDisableBodyScroll, useOutsideClick } from 'app/utils';
import { Overlay } from 'ui';
import { ConfirmFooter } from '../confirm-footer';

import s from './styles.module.scss';

export interface ModalProps extends HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  onClose: () => void;
  closeIcon?: boolean;
  titleWeight?: 'regular' | 'bold' | 'semibold';
  isLoading?: boolean;
  usePortal?: boolean;
  titleClassName?: string;
  minHeight?: string | number;
  transparent?: boolean;
  text?: string;
  withConfirm?: boolean;
  onSubmit?: () => void;
  submitButtonProps?: ButtonProps;
  submitText?: string;
  cancelText?: string;
  footerClassName?: string;
}

const modalRoot = document.getElementById('modal-root') as HTMLElement;

export const Modal = ({
  children,
  title = 'test',
  className,
  onClose,
  closeIcon = true,
  titleWeight = 'semibold',
  usePortal = true,
  titleClassName,
  minHeight,
  transparent,
  text,
  withConfirm,
  submitButtonProps,
  onSubmit,
  isLoading,
  submitText,
  cancelText,
  footerClassName,
  ...props
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [minModalHeight, setMinModalHeight] = useState<number>(100); // set default value for loading state

  useOutsideClick(modalRef as RefObject<HTMLElement>, onClose);
  useDisableBodyScroll(modalRef as RefObject<HTMLElement>);

  useEffect(() => {
    // 56 is a padding (top + bottom = 24 + 32)
    if (modalRef.current) setMinModalHeight(modalRef.current.offsetHeight - 56);
  }, []);

  const modal = (
    <Overlay transparent={transparent} ref={ref}>
      <FocusLock autoFocus={false}>
        <div
          className={cx(s.modal, className)}
          ref={modalRef}
          style={{ minHeight: minHeight || minModalHeight }}
          {...props}
        >
          {closeIcon && (
            <IconButton
              // color="secondary"
              iconName={Icon.icons.close}
              onClick={onClose}
              className={s.modalHeaderIcon}
              showHover
            />
          )}
          {title && (
            <div className={s.modalHeader}>
              <Text weight={Text.weight[titleWeight]} className={cx(s.modalHeaderTitle, titleClassName)}>
                {title}
              </Text>
            </div>
          )}
          {text && (
            <div className={s.modalContent}>
              <Text weight={Text.weight.regular} className={s.modalContentText}>
                {text}
              </Text>
            </div>
          )}
          {children}

          {withConfirm && (
            <ConfirmFooter
              onClose={onClose}
              onSubmit={onSubmit}
              submitButtonProps={submitButtonProps}
              isLoading={isLoading}
              submitText={submitText}
              cancelText={cancelText}
              footerClassName={footerClassName}
            />
          )}
        </div>
      </FocusLock>
    </Overlay>
  );

  return usePortal ? createPortal(modal, modalRoot) : modal;
};
