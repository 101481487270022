import { ToastContainer } from 'react-toastify';
import { IconButton, Icon } from '@intellecteu/common-ui';

import s from './styles.module.scss';

const CloseButton = ({ closeToast }: { closeToast?: () => any }) => (
  <IconButton onClick={closeToast} className={s.closeButton} iconName={Icon.icons.close} />
);

export const Toast = () => <ToastContainer className={s.notification} closeButton={<CloseButton />} />;
