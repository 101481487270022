import { ApplicationDto } from 'data';

export type PublicAppUrlProps = {
  domain?: string;
  name?: string;
  type?: ApplicationDto.type;
  validatorParent?: string;
  showCustomDomain?: boolean;
};

const BASE_URL = window._env_?.CONFIG_BASEURL || import.meta.env.VITE_BASE_URL;

export const getPublicAppUrl = (props: PublicAppUrlProps) => {
  const { domain, name, type, validatorParent, showCustomDomain } = props;
  const protocol = 'https://';
  const targets = BASE_URL.split(protocol);
  const domainUrl = targets.pop();

  let res = protocol;
  const resDomain = domain ? `${domain}.` : '';
  const resUIAPP = name && validatorParent == null ? `${showCustomDomain ? '' : `/${name}`}` : '';
  const resBEAPP = name ? `/${name}/api` : '';

  if (type === ApplicationDto.type.UI) {
    res += `${resDomain}${domainUrl}${resUIAPP}`;
  } else {
    res += `${resDomain}${domainUrl}${resBEAPP}`;
  }

  if (resUIAPP || resBEAPP) return res;

  return '';
};
