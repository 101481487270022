import { ReactNode, useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const baseOptions: ToastOptions = {
  position: 'bottom-right',
  draggable: true,
  closeOnClick: false,
  autoClose: 4000,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  theme: 'light',
};

export const useNotifications = () => {
  const showSuccessMessage = useCallback((message: string | ReactNode, options?: ToastOptions, onOpen?: () => void) => {
    const opts = {
      ...baseOptions,
      ...options,
      autoClose: options?.delay ? 7000 : baseOptions.autoClose,
      onOpen,
    };
    toast.success(message, opts);
  }, []);

  const showErrorMessage = useCallback((message: string | ReactNode, options?: ToastOptions, onOpen?: () => void) => {
    toast.error(message, { ...baseOptions, ...options, onOpen });
  }, []);

  const showWarningMessage = useCallback(
    (message: string | ReactNode, options?: ToastOptions, onOpen?: () => void): void => {
      toast.warning(message, { ...baseOptions, ...options, onOpen });
    },
    [],
  );

  const showInfoMessage = useCallback(
    (message: string | ReactNode, options?: ToastOptions, onOpen?: () => void): void => {
      const opts = {
        ...baseOptions,
        ...options,
        autoClose: options?.delay ? 7000 : baseOptions.autoClose,
        onOpen,
      };
      toast.info(message, opts);
    },
    [],
  );

  const dismiss = useCallback((): void => {
    toast.dismiss();
  }, []);

  // Feature specific notifications

  // PRUNING
  const showPruningScheduledMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Pruning for <strong>{name}</strong> was scheduled
      </div>,
    );

  const showPruningRemovedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Pruning for <strong>{name}</strong> was removed
      </div>,
    );

  // BACKUPS
  const showBackupScheduledMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Backup for <strong>{name}</strong> was scheduled
      </div>,
    );

  const showBackupRemovedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Backup schedule for <strong>{name}</strong> was removed
      </div>,
    );

  // PARTIES
  const showPartyCreatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Party for <strong>{name}</strong> was created
      </div>,
    );

  // USERS
  const showUserCreatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        User <strong>{name}</strong> was created
      </div>,
    );

  const showRightsUpdatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        <strong>{name}</strong>`s rights were updated
      </div>,
    );

  const onSuccessUserRemove = (name: string) =>
    showSuccessMessage(
      <div>
        User <strong>{name}</strong> was removed
      </div>,
    );

  // FILES
  const showFileUploadedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        File <strong>{name}</strong> was uploaded
      </div>,
    );

  const onSuccessFileRemove = (name: string): void =>
    showSuccessMessage(
      <div>
        File <strong>{name}</strong> was removed
      </div>,
    );

  const onSuccessUploadDarToParticipantMessage = (fileName: string, participantName: string): void =>
    showSuccessMessage(
      <div>
        File <strong>{fileName}</strong> was deployed to <strong>{participantName}</strong>
      </div>,
    );

  const onSuccessAddDarToParticipantMessage = (fileName: string, participantName: string): void =>
    showSuccessMessage(
      <div>
        DAR file <strong>{fileName}</strong> was added to participant <strong>{participantName}</strong>
      </div>,
    );

  const onSuccessAddDarToRemoteParticipantMessage = (fileName: string, participantName: string): void =>
    showSuccessMessage(
      <div>
        DAR file <strong>{fileName}</strong> was added to remote participant <strong>{participantName}</strong>
      </div>,
    );

  // DOMAINS
  const showDomainCreatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Domain <strong>{name}</strong> was created
      </div>,
      {},
    );

  const onSuccessDomainUpdatedMessage = (name: string): void =>
    showSuccessMessage(
      <div>
        Domain <strong>{name}</strong> was updated
      </div>,
      {},
    );

  // REMOTE DOMAIN
  const onSuccessRemoteDomainCreatedMessage = (name: string): void =>
    showSuccessMessage(
      <div>
        Remote domain <strong>{name}</strong> was created
      </div>,
      {},
    );

  const onSuccessRemoteDomainUpdatedMessage = (name: string): void =>
    showSuccessMessage(
      <div>
        Remote domain <strong>{name}</strong> was updated
      </div>,
      {},
    );

  // PARTICIPANTS
  const onSuccessParticipantCreatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Participant <strong>{name}</strong> was created
      </div>,
      {},
    );

  const onSuccessParticipantUpdatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Participant <strong>{name}</strong> was updated
      </div>,
      {},
    );

  const onSuccessConnectParticipantToDomainMessage = (participantName: string, domainName: string): void =>
    showSuccessMessage(
      <div>
        Participant <strong>{participantName}</strong> was connected to domain <strong>{domainName}</strong>
      </div>,
      {},
    );

  // REMOTE PARTICIPANTS
  const onSuccessRemoteParticipantCreatedMessage = (name: string): void =>
    showSuccessMessage(
      <div>
        Remote participant <strong>{name}</strong> was created
      </div>,
    );

  const onSuccessRemoteParticipantUpdatedMessage = (name: string): void =>
    showSuccessMessage(
      <div>
        Remote participant <strong>{name}</strong> was updated
      </div>,
      {},
    );

  const onSuccessConnectRemoteParticipantToDomainMessage = (participantName: string, domainName: string): void =>
    showSuccessMessage(
      <div>
        Remote participant <strong>{participantName}</strong> was connected to domain <strong>{domainName}</strong>
      </div>,
      {},
    );

  // APPLICATIONS
  const onSuccessApplicationCreated = (name: string) =>
    showSuccessMessage(
      <div>
        Application <strong>{name}</strong> was created
      </div>,
      {},
    );

  const onSuccessApplicationUpdated = (name: string) =>
    showSuccessMessage(
      <div>
        Application <strong>{name}</strong> was updated
      </div>,
      {},
    );

  // VALIDATORS
  const onSuccessCreateIdentitiesDump = (name: string) =>
    showSuccessMessage(
      <div>
        Validator participant identities saved as secret: <code>{name}</code>
      </div>,
    );

  const onSuccessValidatorCreateMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Validator <strong>{name}</strong> was created
      </div>,
    );

  const onSuccessValidatorUpdateMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Validator <strong>{name}</strong> was updated
      </div>,
      {},
    );

  const onSuccessValidatorRemove = (name: string) =>
    showSuccessMessage(
      <div>
        Validator <strong>{name}</strong> was removed
      </div>,
    );

  return {
    dismiss,
    showSuccessMessage,
    showErrorMessage,
    showWarningMessage,
    showInfoMessage,
    showPruningScheduledMessage,
    showPruningRemovedMessage,
    showBackupScheduledMessage,
    showBackupRemovedMessage,
    showPartyCreatedMessage,
    showUserCreatedMessage,
    onSuccessUserRemove,
    showRightsUpdatedMessage,
    showFileUploadedMessage,
    onSuccessFileRemove,
    onSuccessUploadDarToParticipantMessage,
    onSuccessAddDarToParticipantMessage,
    onSuccessAddDarToRemoteParticipantMessage,
    showDomainCreatedMessage,
    onSuccessDomainUpdatedMessage,
    onSuccessValidatorCreateMessage,
    onSuccessValidatorUpdateMessage,
    onSuccessValidatorRemove,
    onSuccessCreateIdentitiesDump,
    onSuccessApplicationCreated,
    onSuccessApplicationUpdated,
    onSuccessConnectParticipantToDomainMessage,
    onSuccessParticipantCreatedMessage,
    onSuccessParticipantUpdatedMessage,
    onSuccessRemoteParticipantCreatedMessage,
    onSuccessRemoteParticipantUpdatedMessage,
    onSuccessConnectRemoteParticipantToDomainMessage,
    onSuccessRemoteDomainCreatedMessage,
    onSuccessRemoteDomainUpdatedMessage,
  };
};
