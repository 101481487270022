import { memo, ReactNode } from 'react';
import { InfoItem } from '@intellecteu/common-ui';
import { CaptionSkeleton } from 'ui';

interface IFormCaption {
  isDataLoaded: boolean;
  data: Array<any> | undefined;
  text?: string;
  isTotalView?: boolean;
  total?: number;
  className?: string;
}

// eslint-disable-next-line react/display-name
export const PageSubCaption = memo(({ isDataLoaded, data, text, isTotalView, total, className }: IFormCaption) => {
  let content: string | ReactNode = '';

  if (isDataLoaded && data) {
    if (isTotalView) {
      content = `Total items: ${total}`;
    } else {
      const suffix: string = data.length === 1 ? '' : 's';
      content = `${data.length} ${text}${suffix}`;
    }
  } else {
    content = <CaptionSkeleton />;
  }
  return <InfoItem label={content} className={className} />;
});

PageSubCaption.displayName = 'PageSubCaption';
