import React, { forwardRef } from 'react';
import cx from 'classnames';
import { Text } from '@intellecteu/common-ui';

import s from './styles.module.scss';

export interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
  disabled?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  ({ label, error, className, disabled, ...props }, ref) => {
    return (
      <div className={s.container}>
        {label && (
          <Text
            className={cx(s.containerLabel, disabled ? s.containerDisabled : null)}
            color={error ? Text.color.error : Text.color.darkGrey}
            size={Text.size.m}
          >
            {label}
          </Text>
        )}
        <input
          type="text"
          className={cx(s.containerInput, error && s.containerInputError, className)}
          disabled={disabled}
          autoComplete="new-password"
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
