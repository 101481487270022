import { EventType, RightType } from 'data/constants';
import {
  ApplicationDto,
  DomainDto,
  LogLineDto,
  ParticipantDto,
  RemoteDomainDto,
  RemoteParticipantDto,
  StorageDto,
  ValidatorDto,
} from './openapi';

export type WithoutNullableKeys<Type> = {
  [Key in keyof Type]-?: WithoutNullableKeys<NonNullable<Type[Key]>>;
};

export type ErrorPayload = { error?: string; errorDescription?: string };

export type IError = {
  detail: string;
  status?: number;
  title?: string;
  type?: string;
  response: {
    data?: ErrorPayload;
    statusText?: string;
  };
};

export type Response<T = any> = ErrorPayload & {
  data: T;
  hasError: boolean;
};

export interface IEvent {
  phase: DomainDto.phase | ParticipantDto.phase | undefined;
  source: string;
  type: EventType;
}

export enum FileType {
  'DAR' = 'DAR',
  'UI_PACKAGE' = 'UI_PACKAGE',
}

export type RightDto = {
  type: RightType;
  party?: string;
};

export type UserDto = {
  userId: string;
  primaryParty?: string;
  rights?: RightDto[];
};

export type PartyDto = {
  displayName: string;
  identifier: string;
  isLocal: boolean;
};

export type LogsFilters = {
  numLogs: number;
  referenceTimeStamp: string;
  prevLogs: boolean;
  filter: string;
  level: LogLineDto.level;
};

export type OptionLogLevelType = {
  value: LogLineDto.level;
  label: LogLineDto.level;
};

export const LogLevels: OptionLogLevelType[] = Object.values(LogLineDto.level).map((l) => ({
  value: l,
  label: l,
}));

export interface DomainFilters {
  healthStatus?: boolean;
  phase?: DomainDto.phase;
  storageType?: StorageDto.type;
}

export interface ParticipantFilters {
  healthStatus?: boolean;
  phase?: ParticipantDto.phase;
  storageType?: StorageDto.type;
}

export interface RemoteDomainFilters {
  phase?: RemoteDomainDto.phase;
  healthStatus?: boolean;
}

export interface RemoteParticipantFilters {
  phase?: RemoteParticipantDto.phase;
  healthStatus?: boolean;
}

export interface ApplicationFilters {
  phase?: ApplicationDto.phase;
}

export interface ValidatorFilters {
  phase?: ValidatorDto.phase;
}
