import { ReactElement, useState, Fragment, useEffect } from 'react';
import cx from 'classnames';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ExpandedState,
  getExpandedRowModel,
  SortingState,
  getSortedRowModel,
  FilterFn,
  getFacetedUniqueValues,
} from '@tanstack/react-table';
import { Updater } from '@tanstack/react-query';
import { rankItem } from '@tanstack/match-sorter-utils';
import { Icon, SearchTextInput, SelectInput, type TOption, Text, Pagination } from '@intellecteu/common-ui';
import { PAGE_SIZE } from 'app/constants';
import { TableSkeleton } from 'ui';
import type { ColumnDef, ColumnFiltersState, TableOptions } from '@tanstack/react-table';

import s from './styles.module.scss';

export interface TableFilter {
  label: string;
  columnId: string;
  options: TOption[];
}

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  className?: string;
  wrapperClassName?: string;
  subRowComponent?: ReactElement;
  options?: Omit<TableOptions<T>, 'data' | 'columns' | 'getCoreRowModel'>;
  isPaginated?: boolean;
  isSearchable?: boolean;
  isSortable?: boolean;
  isFetching?: boolean;
  initialSorting?: SortingState;
  filters?: TableFilter[];
  onFiltersChange?: (filters: ColumnFiltersState) => void;
  columnVisibility?: Record<string, boolean>;
  setColumnVisibility?: (updater: Updater<Record<string, boolean>, any>) => void;
}

const emptyHeight = 425;
const pageSizes = [5, 10, 20, 30, 40, 50];

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

export const Table = <T extends object>({
  data = [],
  columns,
  className,
  wrapperClassName,
  subRowComponent: SubRow,
  options,
  isPaginated = true,
  isSearchable,
  isSortable,
  isFetching,
  initialSorting,
  filters,
  onFiltersChange,
  columnVisibility,
  setColumnVisibility,
}: ReactTableProps<T>) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    state: {
      ...(isSortable && { sorting }),
      ...options?.state,
      columnVisibility: { ...columnVisibility },
      expanded,
    },
    manualPagination: true,
    manualFiltering: true,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    // getFilteredRowModel: getFilteredRowModel(), // not needed for manual server-side global filtering
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...(isSortable && { onSortingChange: setSorting, getSortedRowModel: getSortedRowModel() }),
    ...(isSearchable && { globalFilterFn: fuzzyFilter }), //  onGlobalFilterChange: setGlobalFilter,
    ...options,
    autoResetPageIndex: false, // turn off page index reset when sorting or filtering
    enableSortingRemoval: false,
    // debugTable: true,
    defaultColumn: {
      minSize: 1,
    },
  });
  const { getState, setPageSize, getRowModel, getColumn, getHeaderGroups, setPageIndex } = table;
  const pageIndex = Number(getState().pagination.pageIndex) + 1;
  const totalItems = table.getCoreRowModel().rows.length;
  const isColumnsSortable = isSortable || options?.manualSorting;

  const searchBlock = isSearchable && (
    <SearchTextInput
      placeholder="Search by name"
      // value={table.getState().globalFilter}
      onClear={() => table.setGlobalFilter('')}
      onSearchClick={(value: string) => table.setGlobalFilter(value)}
      // onChange={(e) => table.setGlobalFilter(e.target.value)}
    />
  );

  useEffect(() => {
    if (isPaginated) setPageSize(PAGE_SIZE);
  }, [isPaginated]);

  useEffect(() => onFiltersChange?.(columnFilters), [columnFilters]);

  return (
    <div className={cx(s.wrapper, wrapperClassName)}>
      <div className={s.tableActions}>
        <div className={s.tableActionsFilter}>
          {filters?.length ? (
            <>
              {searchBlock}
              {filters.map(({ label, columnId, options: optionsFromConfig }: TableFilter) => (
                <SelectInput
                  key={`${label}-filter`}
                  label={label}
                  className={s.tableActionsFilterSelect}
                  defaultValue={optionsFromConfig[0]}
                  options={optionsFromConfig}
                  onChange={(v: TOption) => {
                    return getColumn(columnId)?.setFilterValue(v.value === '' ? null : v.value);
                  }}
                />
              ))}
            </>
          ) : (
            searchBlock
          )}
        </div>
      </div>

      {/** isFetching
       * It is not suitable for pages where we have a constant update of data after a time interval.
       * It was needed here to avoid resetting the filters.
       * */}
      {isFetching ? (
        <TableSkeleton />
      ) : (
        <table className={cx(s.table, className)}>
          <thead className={s.tableHead}>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={s.tableHeadCell}
                    style={{
                      width: header.getSize() !== 0 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() && isColumnsSortable ? s.cellWithSorting : ''}
                        onClick={header.column.getToggleSortingHandler()}
                        aria-hidden
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {header.column.getCanSort() && isColumnsSortable && (
                          <div className={s.sortButtons}>
                            <Icon
                              icon={Icon.icons.chevronUp}
                              size="xxs"
                              className={
                                (header.column.getIsSorted() as string) === 'asc' ? s.sortIconActive : s.sortIcon
                              }
                            />
                            <Icon
                              icon={Icon.icons.chevronDown}
                              size="xxs"
                              className={
                                (header.column.getIsSorted() as string) === 'desc' ? s.sortIconActive : s.sortIcon
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className={s.tableBody}>
            {!table.getFilteredRowModel().rows.length && (
              <tr style={{ height: `${emptyHeight}px` }} className={s.emptyBlock}>
                <td colSpan={columns.length}>
                  <Text size="l" color={Text.color.darkBlue} component="span">
                    <span className={s.emptyBlockMessage}>No records found by this filter</span>
                  </Text>
                </td>
              </tr>
            )}

            {getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr key={row.id} className={s.tableBodyRow}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={cx(s.tableBodyCell, cell.column.columnDef.meta?.isLink && s.tableBodyCellLink)}
                      key={cell.id}
                      style={{
                        width: cell.column.getSize() !== 0 ? cell.column.getSize() : undefined,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() ? (
                  <tr>
                    <td colSpan={3}>{SubRow}</td>
                  </tr>
                ) : null}
              </Fragment>
            ))}
          </tbody>
        </table>
      )}

      {isPaginated && table.getFilteredRowModel().rows.length > 0 && (
        <Pagination
          total={options?.rowCount || totalItems}
          current={pageIndex}
          onPageIndexChange={(currentPage) => setPageIndex(currentPage - 1)}
          pageSize={getState().pagination.pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={(v: TOption) => {
            setPageSize(Number(v?.value));
            setPageIndex(0);
          }}
          isDisabled={(options?.rowCount || totalItems) < PAGE_SIZE}
        />
      )}

      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </div>
  );
};
